import React from 'react';
import { Container } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import img1 from '../Assets/Gallery/img1.jpg'
import img2 from '../Assets/Gallery/img2.avif'
import img3 from '../Assets/Gallery/img3.avif'
import img5 from '../Assets/Gallery/img5.avif'
import img6 from '../Assets/Gallery/img6.avif'
import img7 from '../Assets/Gallery/img7.avif'
import img8 from '../Assets/Gallery/img8.avif'
import img9 from '../Assets/Gallery/img9.avif'
import img10 from '../Assets/Gallery/img10.avif'
import img11 from '../Assets/Gallery/img11.avif'
import img12 from '../Assets/Gallery/img12.avif'
import img13 from '../Assets/Gallery/img13.webp'
import img14 from '../Assets/Gallery/img14.webp'

import ImageGallery from 'react-image-gallery';
const images = [
    {
        original: img1,
        thumbnail: img1,
        alt:"img1",
    },
    {
        original: img2,
        thumbnail: img2,
    },
    {
        original: img3,
        thumbnail: img3,
    },
    {
        original: img5,
        thumbnail: img5,
    }
    ,
    {
        original: img6,
        thumbnail: img6,
    },
    {
        original: img7,
        thumbnail: img7,
    },
    {
        original: img8,
        thumbnail: img8,
    },
    {
        original: img9,
        thumbnail: img9,
    },
    {
        original: img10,
        thumbnail: img10,
    } ,
    {
        original: img11,
        thumbnail: img11,
    },
    {
        original: img12,
        thumbnail: img12,
    },
    {
        original: img13,
        thumbnail: img13,
    },
    {
        original: img14,
        thumbnail: img14,
    }
];


function Gallery() {

    return (
        <section className='work_samples_div'>
            <Container>
                <div>
                    <ImageGallery autoPlay items={images} />
                </div>
            </Container>
        </section>
    )
}

export default Gallery;
