import React from 'react';
import facebook from "../Assets/facebook.svg";
import instagram from "../Assets/instagram.svg";
import twitter from "../Assets/twitter.svg";
import spotify from "../Assets/spotify.svg";
import youtube from "../Assets/youtube01.svg";
import insider from "../Assets/insider.svg";
import nodwin from "../Assets/nodwin.svg";
import logoFooter from '../Assets/logo.png';

import '../Styles/Home.css';

function Footer() {

    return (
        <div>

            <section className='socialBlocks'>
                <div className='alignCenter'>
                    <div>
                        <img src={logoFooter} className="logoFooter" alt='bvs footer logo'></img>
                    </div>
                    <div className='flexBlock'>
                        <a href="https://nodwingaming.com/" target="_blank">
                            <img src={nodwin} className="FlinkLogo" alt="nodwin logo" />
                        </a>
                        <p className="sepratorl">|</p>
                        <img src={insider} className="FlinkLogo" alt="paytm insider logo" />
                    </div>
                {/* <div className='spaceBottomF'>
                        <a className='footerHeadLink'>FAQ</a>
                    </div> */}
                </div>
                <div className='socialIconBlock'>
                    <a href='https://www.instagram.com/breezervividshuffle/?hl=en' target="_blank">
                        <img src={instagram} className="socialIcon mrl" alt='instagram bvs'></img>
                    </a>
                    <a href='https://www.facebook.com/breezervividshuffle/' target="_blank">
                        <img src={facebook} className="socialIcon mrl" alt='facebook bvs'></img>
                    </a>
                    <a href='https://twitter.com/bvshuffle' target="_blank">
                        <img src={twitter} className="socialIcon mrl" alt='twitter bvs'></img>
                    </a>
                    {/* <a href='' target="_blank">
                        <img src={spotify} className="socialIcon mrl" alt='spotify bvs'></img>
                    </a> */}
                    <a href='https://www.youtube.com/channel/UCkvaGaPik8kNUlUTY_QLtuA' target="_blank">
                        <img src={youtube} className="socialIcon" alt='youtube bvs'></img>
                    </a>
                </div>
            </section>
        </div>
    )
}

export default Footer