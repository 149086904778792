import '../Styles/Header.css';
import Logo from '../Assets/logo.png';
import React, { useState, useEffect } from 'react';

import { Nav, Navbar, NavDropdown } from 'react-bootstrap';


function Header() {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 200) {
      setColorchange(true);
    }
    else {
      setColorchange(false);
    }
  };
  window.addEventListener('scroll', changeNavbarColor);

  const [show, setShow] = useState(false);
  const [showw , setShoww] = useState(false);
  const [showDbb , setShowDbb] = useState(false);

  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }
// block Party
  const showFestival = (e) => {
    setShoww(!showw);
  }
  const hideFestival = e => {
    setShoww(false);
  }
// festival
  const showDb = (e) => {
    setShowDbb(!showDbb);
  }
  const hideDb = e => {
    setShowDbb(false);
  }
  return (
    <div >
      <Navbar className={colorChange ? 'navbarr colorChange' : 'navbarr'} fixed="top"variant="dark" expand="lg">
        <Navbar.Brand href="/" className="App-logo">
          <img src={Logo} className="logoHeader" alt="breezer vivid shuffle logo"></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link className='navLinkStyle' href="/">Home</Nav.Link>
            <NavDropdown className='navLinkStyle' title="Block Party"
              id="collasible-nav-dropdown"
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}>
              <NavDropdown.Item className='dropdownStyle' href="/hyderabad">Hyderabad</NavDropdown.Item>
              <NavDropdown.Item className='dropdownStyle' href="/guwahati">Guwahati</NavDropdown.Item>
            </NavDropdown>

            <NavDropdown className='navLinkStyle' title="Festival"
              id="collasible-nav-dropdown"
              show={showw}
              onMouseEnter={showFestival}
              onMouseLeave={hideFestival}>
              <NavDropdown.Item className='dropdownStyle' href="/mumbai">Mumbai</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className='navLinkStyle' title="BOTS Dance Battle"
              id="collasible-nav-dropdown"
              show={showDbb}
              onMouseEnter={showDb}
              onMouseLeave={hideDb}>
              <NavDropdown.Item className='dropdownStyle' href="/botsdancebattle">BOTS Dance Battle</NavDropdown.Item>
              <NavDropdown.Item className='dropdownStyle' target="_blank" href="https://insider.in/breezer-vivid-shuffle-beats-of-the-streets-dance-battle-oct15-2022/event">Register</NavDropdown.Item>
            </NavDropdown>
            {/* <Nav.Link className='navLinkStyle' href="/botsdancebattle">BOTS Dance Battle</Nav.Link> */}
            <Nav.Link className='navLinkStyle' href="/faq">FAQ</Nav.Link>
          
            <a href="https://insider.in/breezer-vivid-shuffle/article" target="_blank" className="btnBuyTickets">Buy Tickets</a>
          </Nav>

        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default Header
