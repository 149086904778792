import React from 'react';
import '../Styles/faq.css';
import leftAttachF from "../Assets/city/leftAttachF.svg";
import faqHead from "../Assets/SVG/faqHead.svg";
import { Row, Col } from 'react-bootstrap';

function FAQ() {

    return (
        <div>
            <section className='faqBlock'>
                <div className='relative'>
                    <img src={leftAttachF} className="fixedFramef" alt='cityFrame'></img>
                    <img src={leftAttachF} className="fixedFrameRightf" alt='cityFrame'></img>
                    <div className='cityInfoBlock'>
                        <div className='textContainerBlock'>
                            <div className='centerFaq'>
                                <img src={faqHead} className="faqHeadMain" alt='faq Head'></img>
                            </div>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard mobileSpaceFaq customHeight01'>
                                        <p className='question'>Do I have to be fully vaccinated (2 doses) to attend the event?</p>
                                        <p className='answer'>Yes, you need to be fully vaccinated to attend the festival. By purchasing the ticket, you are declaring that you have taken both vaccine shots at least 14 days prior to the event date.</p>
                                    </div>
                                </Col>
                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard customHeight01'>
                                        <p className='question'>Do you have medical assistance on the ground?</p>
                                        <p className='answer'>Yes, there will be on-ground medical professionals as well as an ambulance on standby.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={12} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>Do I have to bring a printout of my event ticket?</p>
                                        <p className='answer'>BREEZER Vivid Shuffle always strives to be as green as we can be, so the folks at the box office are happy to see your ticket on your mobile phone. Be warned that there is a limited mobile network at the venue, so remember to save the ticket on your phone. You can also show the SMS sent by Paytm Insider at the time of ticket purchase.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard mobileSpaceFaq'>
                                        <p className='question'>Can I transfer my ticket to someone else?</p>
                                        <p className='answer'>Sorry, but you won’t be able to transfer your tickets. So plan ahead and you’ll be fine.
                                        </p>
                                    </div>
                                </Col>
                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>Can I cancel my ticket?</p>
                                        <p className='answer'>Sorry, no backsies here. We will not be able to cancel/refund tickets.

                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>When will I receive my wristband?
                                        </p>
                                        <p className='answer'>
                                            With every ticket, all attendees will need to collect their wristbands for the event. You can collect your wristband at the box office before you enter the event. Please take very good care of your wristband because you will not be permitted inside the venue without one.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>What kind of ID do I need?

                                        </p>
                                        <p className='answer'>
                                            To enter BREEZER Vivid Shuffle, you must present identification. So please carry a valid, government-issued ID, such as a passport, Aadhar Card, PAN Card, or driver’s license. Letters from your grandma claiming how old you are won’t do, sorry :(
                                            <br></br>
                                            Oh and btw, you will need to present your vaccination certificate (both doses) at entry. So keep those handy too!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>What if I lose my band mid-event and need to get out for a bit, can I re-enter?


                                        </p>
                                        <p className='answer'>Wristbands that are lost or misplaced before or during the event will not be replaced. You will not be allowed to re-enter the festival without your wristband so we would suggest you keep your wristband on throughout, come what may especially for the 2-day festival in Mumbai! Sleep, showers, after-parties, and everything that comes in between - the wristband must be protected!

                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard mobileSpaceFaq customHeight01'>
                                        <p className='question'>Is alcohol available at the event
                                        </p>
                                        <p className='answer'>Yup, but you’ll only be served alcohol if you’re above the legal drinking age! (perhaps one of the few perks of growing older). And if you do drink, be good ok? Handle thy liquor well, so that everyone can have a fantastic time!
                                        </p>
                                    </div>
                                </Col>
                                <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard customHeight01'>
                                        <p className='question'>Can I smoke? Can I get high?
                                        </p>
                                        <p className='answer'>BREEZER Vivid Shuffle is a drug-free festival. Anyone found at the festival with any illegal substances will be removed immediately.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>Can I bring my alcohol and make it a BYOB gig?

                                        </p>
                                        <p className='answer'>Outside food and drinks are not allowed inside the venue; food and beverages will be available for sale (You will discover some great brands at the event!).
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>What all can I bring with me to BREEZER Vivid Shuffle then?
                                        </p>
                                        <p className='answer'>Your friends and family, good vibes, and everything in between.
                                            <br></br>
                                            <br></br>
                                            * Things that aren’t allowed are:
                                            <br></br>
                                            <br></br>
                                            * Illegal substances, drugs, and related paraphernalia
                                            <br></br>
                                            <br></br>
                                            * Cigarettes
                                            <br></br>
                                            <br></br>
                                            * Large chains or spiked jewellery<br></br>
                                            <br></br>
                                            * Weapons of any kind - including pocket knives, pepper spray, guns, fireworks, or any explosives. The only burns you’re allowed to diss is through your wit.<br></br>
                                            <br></br>
                                            * Outside food or beverages (including alcohol)<br></br>
                                            <br></br>
                                            * Professional photo, video or audio equipment (No detachable lenses, tripods, big zooms or commercial-use rigs)
                                        </p>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>Will you confiscate my bag at the entry?
                                        </p>
                                        <p className='answer'>No, your bag will not be confiscated at the entry; however, we will need to check the elements in your bag to ensure none of the above-mentioned things are present.

                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>What time does the event start and end each day?
                                        </p>
                                        <p className='answer'>We wish the party never had to end! But we’ve only got a restricted few hours so we intend to make the most of ‘em.
                                            <br></br>
                                            <br></br>
                                            Entry to the events are mentioned below:
                                            Hyderabad and Guwahati:
                                            5:00 Gates open; ending at 10PM
                                            <br></br>
                                            <br></br>
                                            Mumbai:
                                            <br></br>
                                            DAY 1
                                            <br></br>
                                            Gates open- 1:00 PM; ending at 10PM
                                            <br></br>
                                            DAY 2
                                            <br></br>
                                            Gates open- 5:00 PM; ending at 10:00 PM
                                            <br></br>
                                            <br></br>
                                            *Indicative timings only, Subject to change basis final plans.
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>Is there an official hashtag?
                                        </p>
                                        <p className='answer'>Yes! Just like no celebration is complete without one, we have an official hashtag too. Use #BREEZERVividShuffle and #BeatsOfTheStreets on Twitter, Instagram, and Facebook, and you might just get a shoutout from us!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='faqBottomSpace'>
                                <Col sm={12} xs={12} md={2} lg={12} xl={12}>
                                    <div data-aos="fade-up" data-aos-duration="2000" className='faqCard'>
                                        <p className='question'>What can we expect at the event? What's different this year?
                                        </p>
                                        <p className='answer'>This season, BREEZER Vivid Shuffle is transforming to a celebration of all things hip hop & coming to a city close to you. The event will transition from being a primarily dance based competitive event to a hip hop festival with the best Indian music acts at the forefront along with open dance cyphers, workshops, street style souk & delicious food!
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default FAQ
