import React from 'react';
import mainBanner from "../Assets/Home/mainBanner.webp";
import mainBannerMobile from "../Assets/Home/mainBannerMobile.webp";
import leftCar from "../Assets/leftCar.svg";
import Bots from "../Assets/bots.svg";
import rightCar from "../Assets/rightCar.svg";
import leftChain from "../Assets/leftChain.svg";
import AboutHead from "../Assets/aboutHead.svg";
import whtsHead from "../Assets/whtsHead.svg";
import whtsHeadM from "../Assets/whtsM.svg";
import sbaHead from "../Assets/sbaHead.svg";
import sbaHeadM from "../Assets/sbaHeadM.svg";
import dml from "../Assets/dml.svg";
import dmlM from "../Assets/dmlM.svg";
import Mumbai from "../Assets/city/mumbai.png";
import DDC from "../Assets/city/ddc.png";
import Guwahati from "../Assets/city/guwahati.png";
import Hyderabad from "../Assets/city/hyderabad.png";
import kritiSanon from "../Assets/Ambassadors/kriti-sanon.png";
import Vijay from "../Assets/Ambassadors/vijay.png";
import socialProps from "../Assets/socialProps.svg";

import Gallery from '../../src/Components/Gallery.js';

import '../Styles/Home.css';
import { Row, Col} from 'react-bootstrap';



function Home() {

    return (
        <div>
            <section className='bannerBlock'>
                <div className='mainBannerBlock'>
                    <img src={mainBanner} alt='BREEZER vivid shuffle banner' className='mainBannerImg' />
                    <img src={mainBannerMobile} alt='BREEZER vivid shuffle banner' className='mainBannerImgMobile' />
                </div>
            </section>
            <section className='aboutBlock'>
                <div className='widthCustom'>
                <img src={leftChain} className="chainAttach" alt='props chain'></img>
                <img src={leftChain} className="chainAttachRight" alt='props chain'></img>
                <div className='aboutAlign'>
                    <img data-aos="fade-up" data-aos-duration="2000" src={AboutHead} className="aboutHeading" alt="about heading" />
                    <p data-aos="fade-up" data-aos-duration="2000" className='para'> <span className='highlightAbout'>BREEZER Vivid Shuffle Season 6</span> is bringing India’s biggest hip hop movement to your hood.</p>
                    <p data-aos="fade-up" data-aos-duration="2000"  className='para'>After five solid seasons, Season 6 transforms the platform into a musically inspired exhibit, Championed by the biggest hip hop music talents. Celebrating the culture of the streets, there are also open dance cyphers, street-style souk, and of course the School of Shuffle. </p>
                    <p data-aos="fade-up" data-aos-duration="2000" className='para'>We’re taking the   <span className='highlight'>#BeatsOfTheStreets</span>  across the country, with Block Parties in Hyderabad and Guwahati and a two-day festival in Mumbai, all celebrating the spirit of hip hop across the spectrum.</p>
                </div>
                <div className='displatF'>
                    <img data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="ease-in-out" src={leftCar} alt='props BREEZER vivd shuffle' className='leftCar' />
                    {/* <div className='spaceAround'></div> */}
                    <img src={Bots} className="botsObject zoom-in-zoom-out " alt='props bvs'></img>
                    <img data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="ease-in-out" src={rightCar} alt='props BREEZER vivd shuffle' className='rightCar' />
                </div>
                </div>
            </section>
            <div className='seprator'></div>
            <section className='whtsBlock'>
                <div className='bgMain'>
                <div className='headingBlockThird'>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" src={whtsHead} className="whtsImg" alt="what's happening this season"/>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" src={whtsHeadM} className="whtsImgM" alt="what's happening this season"/>
                </div>
                <div className='cityCardsSpace'>
                    <Row>
                    <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <div className='alignCenter spaceCards'>
                                <img data-aos="fade-up" data-aos-duration="1000" src={Hyderabad} className="cityCards" alt="Hyderabad BVS"></img>
                                <div>
                                <a href='/hyderabad' className='knwMoreBtn'>Know More</a>

                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <div className='alignCenter spaceCards'>
                                <img data-aos="fade-up" data-aos-duration="1000" src={Mumbai} className="cityCards" alt="Mumbai BVS"></img>
                                <div>
                                <a href='/mumbai' className='knwMoreBtn' >Know More</a>
                                </div>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={3} lg={3} xl={3}>
                            <div className='alignCenter spaceCards'>
                                <img data-aos="fade-up" data-aos-duration="1000" src={Guwahati} className="cityCards" alt="Guwahati BVS"></img>
                                <div>
                                <a href='/guwahati' className='knwMoreBtn' >Know More</a>

                                </div>
                            </div>
                        </Col>
                        {/* className='mobileCard' */}
                        <Col  sm={12} xs={12} md={3} lg={3} xl={3}>
                            <div className='alignCenter spaceCards'>
                                <img data-aos="fade-up" data-aos-duration="1000" src={DDC} className="cityCards" alt="Digital Dance Competition BVS"></img>
                                <div>
                                <a href='/botsdancebattle' className='knwMoreBtn'>Know More</a>

                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                </div>
                {/* <div className="clients-slideshow-main slideright-r"></div> */}
            </section>
            {/* <div className='seprator'></div>
            <section className='ddcBlock'>
                <div className='alignCenter'>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" src={ddcHead} className="ddcImg" alt="digital dance competition"/>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" src={ddcHeadM} className="ddcImgM" alt="digital dance competition"/>
                    <p className='cmngSoon'>Coming Soon</p>
                </div>
            </section> */}
            <div className='seprator'></div>
            <section className='sbaBlock'>
                <div className='alignCenter'>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" src={sbaHead} className="sbaImg" alt="Shuffle Brand Ambassadors"/>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in" src={sbaHeadM} className="sbaImgM" alt="Shuffle Brand Ambassadors"/>
                </div>
                <div className='ambassadorsCardsSpace'>
                    <Row>
                        <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <div className='alignCenter'>
                                <img data-aos="zoom-in-up" data-aos-duration="2000" data-aos-easing="ease-in" src={kritiSanon} className="ambCards" alt="kritiSanon BVS"></img>
                            </div>
                        </Col>
                        <Col sm={12} xs={12} md={6} lg={6} xl={6}>
                            <div className='alignCenter'>
                                <img data-aos="zoom-in-down" data-aos-duration="2000" data-aos-easing="ease-in" src={Vijay} className="ambCards" alt="VijayDevarkonda BVS"></img>
                            </div>
                        </Col>
                    </Row>
                </div>
            </section>
            <div className='seprator'></div>
            <section className='ImageGalleryBlock'>
            <div className='alignCenter'>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in-out-back" src={dml} className="galleryImg" alt="Image Gallery BREEZER Vivid Shuffle"/>
                    <img data-aos="flip-up" data-aos-duration="1000" data-aos-easing="ease-in-out-back" src={dmlM} className="galleryImgM" alt="Image Gallery BREEZER Vivid Shuffle"/>
                </div>
                <div className='gallerySpace'>
                    <Gallery/>
                </div>
            </section>
            <div className='seprator'></div>
            <div className='propsSocial'>
                <img src={socialProps} className="socialPropsImg" alt='social props bvs'></img>
            </div>
        </div>
    )
}

export default Home