import React from 'react';
import '../Styles/Mumbai.css';
import hyderabadBlock from "../Assets/city/hyderabadBlock.svg";
import leftAttach from "../Assets/city/leftAttach.svg";
import rightAttach from "../Assets/city/rightAttach.svg";
import BrodhaV from "../Assets/city/lineUp/BrodhaV.png";
import SeedheMaut from "../Assets/city/lineUp/SeedheMaut.png";
import Hanumankind from "../Assets/city/lineUp/Hanumankind.png";
import DJKANi from "../Assets/city/lineUp/DJKANi.png";
import lineup from "../Assets/city/lineUp/lineup.svg";
import { Row, Col } from 'react-bootstrap';


function Hyderabad() {
    // Set the date we're counting down to
    var countDownDate = new Date("Nov 5, 2022").getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        // var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        // var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        // var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        // var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        /*document.getElementById("hyd").innerHTML = days + "d " + hours + "h "
            + minutes + "m " + seconds + "s ";*/

        // If the count down is over, write some text 
        /*if (distance < 0) {
            clearInterval(x);
            document.getElementById("hyd").innerHTML = "EXPIRED";
        }*/
    }, 1000);
    return (
        <div>
            <section className='hyderabadPageBlock'>
                <div className='relative'>
                    <img src={leftAttach} className="fixedFrame" alt='cityFrame'></img>
                    <img src={rightAttach} className="fixedFrameRight" alt='cityFrame'></img>

                    <div className='cityInfoBlock'>
                        <div className='alignCenter'>
                            <img src={hyderabadBlock} className="cityImageBlock" alt="hyderabad breezer vivid shuffle"></img>
                            <p className='eventType'>Block Party</p>
                            <p className='eventDate'>5th November</p>

                            {/* <div className='announceBlock'>
                                <p className='paTxt'>Countdown to event</p>
                                <p id="hyd"></p>
                            </div> */}
                        </div>
                        <div className='lineUpdDiv'>
                        <img src={lineup} className="lineUpHead" alt='line up heading'></img>
                            <Row>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={BrodhaV} className="lineUpImg" alt='brodha v bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={SeedheMaut} className="lineUpImg" alt='Seedhe Maut bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={Hanumankind} className="lineUpImg" alt='Hanumankind bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={DJKANi} className="lineUpImg" alt='DJKANi bvs'></img>
                                </Col>
                            </Row>

                        </div>
                        <div className='textContainerBlock'>
                            <p className='paraCityBlock'>The streets are calling for you to feel it, own it, and live it. BREEZER Vivid Shuffle brings the kings of hip hop to the City of Nizams! </p>

                            <p className='paraCityBlock'>The Block Party blends the #BeatsOfTheStreets with the hustling spirit of Hyderabad. The music takes it off the hook with the best of Indian hip hop music talent bringing their own fine tunes.
                            </p>

                            <p className='paraCityBlock'>Shoutout to all you dancers who can battle for supremacy at the open dance cyphers. With photo-ops, delicious food, and street style souk, this Block Party is the place to be in Hyderabad.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Hyderabad