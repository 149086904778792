import './App.css';
import {BrowserRouter, Route, Routes} from  "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-image-gallery/styles/css/image-gallery.css";

import Home from './Components/Home';
import Header from './Components/Header';
import Hyderabad from './Components/Hyderabad';
import Mumbai from './Components/Mumbai';
import Guwahati from './Components/Guwahati';
import FAQ from './Components/FAQ';
import Footer from './Components/Footer';

import AOS from 'aos';
import 'aos/dist/aos.css';
import DanceBattle from './Components/DanceBattle';


function App() {
  
  return (
    <div>
      <BrowserRouter>
      <Header/>
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/hyderabad" element={<Hyderabad/>}/>
        <Route path="/mumbai" element={<Mumbai/>}/>
        <Route path="/guwahati" element={<Guwahati/>}/>
        <Route path="/botsdancebattle" element={<DanceBattle/>}/>
        <Route path="/faq" element={<FAQ/>}/>
      </Routes>
      <Footer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
AOS.init();