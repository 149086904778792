import React from 'react';
import '../Styles/Mumbai.css';
import mumbaiBlock from "../Assets/city/mumbaiBlock.svg";
import leftAttach from "../Assets/city/leftAttach.svg";
import rightAttach from "../Assets/city/rightAttach.svg";
import Agsy from "../Assets/city/mumbai/Agsy.png";
import Dinojames from "../Assets/city/mumbai/DinoJames.png";
import DJProof from "../Assets/city/mumbai/DJProof.png";
import KR$NA from "../Assets/city/mumbai/KR$NA.png";
import PAV4N from "../Assets/city/mumbai/PAV4N.png";
import Raftaar from "../Assets/city/mumbai/Raftaar.png";
import WickedSunny from "../Assets/city/mumbai/WickedSunny.png";

import lineup from "../Assets/city/lineUp/lineup.svg";
import { Row, Col } from 'react-bootstrap';


function Mumbai() {
    var countDownDate = new Date("Nov 12, 2022").getTime();

// Update the count down every 1 second
var x = setInterval(function() {

  // Get today's date and time
  var now = new Date().getTime();
    
  // Find the distance between now and the count down date
  var distance = countDownDate - now;
    
  // Time calculations for days, hours, minutes and seconds
  var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
  // Output the result in an element with id="demo"
  document.getElementById("mumbai").innerHTML = days + "d " + hours + "h "
  + minutes + "m " + seconds + "s ";
    
  // If the count down is over, write some text 
  if (distance < 0) {
    clearInterval(x);
    document.getElementById("mumbai").innerHTML = "EXPIRED";
  }
}, 1000);
    return (
        <div>
            <section className='mumbaiPageBlock'>
                <div className='relative'>
                    <img src={leftAttach} className="fixedFrame" alt='cityFrame'></img>
                        <img src={rightAttach} className="fixedFrameRight" alt='cityFrame'></img>
                    <div className='cityInfoBlock'>
                        <div className='alignCenter'>
                            <img src={mumbaiBlock} className="cityImageBlock" alt="mumbai breezer vivid shuffle"></img>
                            <p className='eventType'>Festival</p>
                            <p className='eventDate'>12th-13th November</p>

                            <div className='announceBlock'>
                                <p className='paTxt'>Countdown to event</p>
                                <p id="mumbai"></p>
                            </div>
                        </div>
                        <div className='lineUpdDiv'>
                        <img src={lineup} className="lineUpHead" alt='line up heading'></img>
                            <Row>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={Raftaar} className="lineUpImg" alt='Raftaar bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={KR$NA} className="lineUpImg" alt='KR$NA bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000"  src={Dinojames} className="lineUpImg" alt='Dinojames bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={Agsy} className="lineUpImg" alt='Agsy bvs'></img>
                                </Col>
                            </Row>
                            <br></br>
                            <Row>
                                <Col sm={12} xs={12} md={1} lg={1} xl={1}>
                                    
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={PAV4N} className="lineUpImg" alt='PAV4N bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={DJProof} className="lineUpImg" alt='DJ Proof bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={3} lg={3} xl={3}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={WickedSunny} className="lineUpImg" alt='WickedSunny bvs'></img>
                                </Col>
                                <Col sm={12} xs={12} md={1} lg={1} xl={1}>
                                    
                                    </Col>
                            </Row>
                        </div>
                        <div className='textContainerBlock'>
                            <p className='paraCityBlock'>A grand celebration of hip hop, the Mumbai Festival takes the #BeatsOfTheStreets to the City That Never Sleeps over two days. </p>

                            <p className='paraCityBlock'>For those who prefer grooving to the beat, the best Indian hip hop music talent takes the night away with cosmic energy and an air of awe. The breaking cypher battle brings together all the dance crews at the top of their game.</p>

                            <p className='paraCityBlock'>Running to the heart of hip hop, you’ll feel street-style souk, photo booths and delicious grub, all enwrapped in an evening that leaves you with the most vibrant memories.</p>

                            <p className='paraCityBlock'>School of Shuffle is bound to keep you hooked with intensive workshops run by those dominating their leagues.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Mumbai