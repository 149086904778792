import React from 'react';
import '../Styles/Mumbai.css';
import guwahatiBlock from "../Assets/city/guwahatiBlock.svg";
import leftAttach from "../Assets/city/leftAttach.svg";
import rightAttach from "../Assets/city/rightAttach.svg";
import Raftaar from "../Assets/city/guwahati/Raftaar.png";
import MebaOfilia from "../Assets/city/guwahati/MebaOfilia.png";
import KhasiBloodz from "../Assets/city/guwahati/KhasiBloodz.png";

import lineup from "../Assets/city/lineUp/lineup.svg";
import { Row, Col } from 'react-bootstrap';



function Guwahati() {
    // Set the date we're counting down to
    var countDownDate = new Date("Nov 19, 2022").getTime();

    // Update the count down every 1 second
    var x = setInterval(function () {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Output the result in an element with id="demo"
        document.getElementById("gwh").innerHTML = days + "d " + hours + "h "
            + minutes + "m " + seconds + "s ";

        // If the count down is over, write some text 
        if (distance < 0) {
            clearInterval(x);
            document.getElementById("gwh").innerHTML = "EXPIRED";
        }
    }, 1000);
    return (
        <div>
            <section className='guwahatiPageBlock'>
                <div className='relative'>
                    <img src={leftAttach} className="fixedFrame" alt='cityFrame'></img>
                    <img src={rightAttach} className="fixedFrameRight" alt='cityFrame'></img>
                    <div className='cityInfoBlock'>
                        <div className='alignCenter'>
                            <img src={guwahatiBlock} className="cityImageBlock" alt="guwahati breezer vivid shuffle"></img>
                            <p className='eventType'>Block Party</p>
                            <p className='eventDate'>19th November</p>
                            <div className='announceBlock'>
                                <p className='paTxt'>Countdown to event</p>
                                <p id="gwh"></p>
                            </div>
                        </div>
                        <div className='lineUpdDiv borderBottom'>
                            <img src={lineup} className="lineUpHead" alt='line up heading'></img>
                            <Row>
                                <Col sm={6} xs={6} md={4} lg={4} xl={4}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={Raftaar} className="lineUpImg" alt='Raftaar bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={4} lg={4} xl={4}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={KhasiBloodz} className="lineUpImg" alt='Khasi Bloodz bvs'></img>
                                </Col>
                                <Col sm={6} xs={6} md={4} lg={4} xl={4}>
                                    <img data-aos="fade-up" data-aos-duration="2000" src={MebaOfilia} className="lineUpImg" alt='Meba Ofilia bvs'></img>
                                </Col>
                            </Row>
                            <p className='lineupWriteup'>With hip hop maestros Khasi Bloodz, Meba Ofilia, Raftaar taking the stage, the Guwahati Block Party is all set to present an electric environment and iconic atmosphere inspired by musical paradigms and the energy of hip hop.</p>
                        </div>
                        <div className='textContainerBlock'>
                            <p className='paraCityBlock'>Guwahati owns the hip hop culture and everything it brings. With Guwahati, the #BeatsOfTheStreets travel to the heart & soul of the East.</p>
                            <p className='paraCityBlock'>Keepin’ it real, the Block Party brings lit music acts from those who dominate the scene, open dance cyphers for all those who run the streets, and street style souk with gear that’s straight fire.
                            </p>
                            <p className='paraCityBlock'>Hosted at an enigmatic venue, the Block Party blends the #BeatsOfTheStreets with the spirit of Guwahati.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Guwahati