import React, { useState } from 'react';
import '../Styles/DanceBattle.css';
import danceBattle from "../Assets/danceBattle/dancebattle.png";
import danceBattleMobile from "../Assets/danceBattle/danceBattleMobile.png";


function DanceBattle() {



    return (
        <div className='bgDanceBattle'>
            <section className='danceBattleBlock'>
                <img src={danceBattle} alt="BOT Dance Battle" className='danceDattleImg'></img>
            </section>
            <section className='danceBattleBlockMobile'>
                <img src={danceBattleMobile} alt="BOT Dance Battle" className='danceDattleImgM'></img>
            </section>
            <section className='txtContainerDb'>
                <div className='registerButton'>
                <a className='registerBtn' target="_blank" href="https://insider.in/breezer-vivid-shuffle-beats-of-the-streets-dance-battle-oct15-2022/event">Register</a>
                </div>
                <div className='blockDb'>
                    <p className='dbPara'>It's Showtime. BREEZER Vivid Shuffle is all set for Season 6!
                        <br></br> <br></br>
                        This edition offers a bigger canvas to hip hop with #BeatsOfTheStreets, transforming the platform into a musically inspired exhibit, championed by the best Indian hip hop music talent at its forefront.
                        <br></br> <br></br>
                        Staying true to the spirit of Shuffle, we’re hosting the #BeatsOfTheStreets Dance Battle digitally for the country’s best hip hop dancers to showcase their signature moves.
                        <br></br> <br></br>
                        With a digital competition celebrating the many styles of hip hop, it’s a battle to “reel your way to the spotlight”. Submit your entries online, impress the panel of rockstar judges, and you win big!
                        <br></br> <br></br>
                        Three winners get the opportunity to perform on the main stage in front of a live audience.
                        And that’s not all. There’s a dope prize pool of ₹6 lakh, a fully-paid-for trip to the event, and a chance to meet with the brand ambassadors.
                        <br></br> <br></br>
                        With India’s biggest hip hop movement featuring your moves, this is where magic happens!
                        <br></br> <br></br>
                        Do it for you, do it for your clan, and do it for BREEZER Vivid Shuffle!
                        <br></br> <br></br>
                        The battle is in motion. Get with it.
                        <br></br> <br></br>
                        <span className='highlightDb'>
                            Reel your way to the Spotlight.
                        </span>
                    </p>
                    {/* new */}
                    <div className='faqDb'>
                        <h1 className='headDb'>GUIDELINES</h1>
                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. How many entry videos can I submit?</p>
                            <p className='answerDb'>A. ONLY 1</p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. How old do I need to be to participate?</p>
                            <p className='answerDb'>A. Every participant needs to be 18 years or above on the day of their entry submission (1st October onwards), a resident of India and of Indian nationality. </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. How many people can be featured in a video?</p>
                            <p className='answerDb'>A. An entry can be as solo, duo and trio. (your video can include 1, 2 or 3 performers but only one entry should be submitted. Entries with more than 3 performers will be disqualified.</p>
                        </div>

                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. Which dance styles are allowed?</p>
                            <p className='answerDb'>A. Street Dance Styles - Popping, Locking, Waacking, Krumping, Electro, House, Hip Hop, Freestyle, Animation, Lite feet, Voguing, Waving, Tutting, and even Fusion. Whatever your style, ensure it flows with the vibe of hip hop. </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. How old do I need to be to participate?</p>
                            <p className='answerDb'>A. Every participant needs to be 18 years or above on the day of their entry submission (1st October onwards), a resident of India and of Indian nationality.  </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. What do I need to keep in mind when applying?</p>
                            <p className='answerDb'>A. For your entry to be valid, make sure you fill in your registration form on Paytm Insider, copy your video URL, and paste it on the form.
                                <br></br>
                                <br></br>
                                * Entries without the right tags and hashtags will be considered invalid.
                                <br></br>
                                <br></br>
                                * Keep your profile public (how else will we see your moves?)
                                <br></br>
                                <br></br>
                                * Limit your videos to 60 seconds (make each second count!)
                                <br></br>
                                <br></br>
                                * Your performance video must be recent. We will only consider entries uploaded on or after 4th October 2022
                                <br></br>
                                <br></br>
                                * Video should be good quality and don’t edit your video.
                                <br></br>
                                <br></br>
                                * Entry should be made with music that’s permitted on Instagram & YouTube. We can allow entries with pre-recorded remixed music (as long as it's not taken down by Instagram / YouTube)
                                <br></br>
                                <br></br>
                                * Ensure that the performer(s) face the camera.
                                <br></br>
                                <br></br>
                                * Get snappy! Judges will be scoring your entries based on the theme, creativity, dance styles, and the way you make your moves.
                                <br></br>
                                <br></br>
                                * Inappropriate sexual gestures are prohibited.</p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. What do I get if I win?</p>
                            <p className='answerDb'>A. There will be 3 winning entries.
                                <br></br><br></br>
                                Each winning entry gets the opportunity to come to one of the 3 cities of BREEZER Vivid Shuffle Season 6. Winner(s) will get to perform on the main stage in front of a live audience and show off amazing signature moves!
                                <br></br><br></br>
                                You also win from a dope cash prize pool of ₹6 lakh, a fully-paid-for trip to the event, and a chance to hang out with the brand ambassadors.
                            </p>
                        </div>

                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. Is it an on-ground battle?
                            </p>
                            <p className='answerDb'>A. This is a digital battle that captures your style through the reel! The judges will view all the video entries and then make their decisions. Three entries will finally be classified winners. The performer(s) of each winning entry will have the chance to perform onstage in front of a live audience. This, truly, is a battle where you reel your way to the mainstage spotlight.
                            </p>
                        </div>
                        <div data-aos="fade-up" data-aos-duration="2000" className='cardDbFaq'>
                            <p className='questionsDb'>Q. I registered but haven’t received my confirmation email. What should I do?
                            </p>
                            <p className='answerDb'>
                                <a href="mailto:social@nodwin.com">social@nodwin.com</a> </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default DanceBattle